import React from "react";
import { Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import ContactForm from './ContactForm';

const ContactFormWrapper = props => {
  const { linkTo } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        { linkTo === 'services' ? (
          <Grid item xs={12} lg={6} className={`${classes.leftCol} move-child-icon-to-right`} component={Link} to="/services">
            <Typography variant="body1" className={classes.whiteText}>Services</Typography>
            <Typography variant="h3" className={classes.whiteText}>Explore our services.</Typography>
            <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
          </Grid>
        ) : linkTo === 'careers' ? (
          <Grid item xs={12} lg={6} className={`${classes.leftCol} move-child-icon-to-right`} component={Link} to="/careers">
            <Typography variant="body1" className={classes.whiteText}>Careers</Typography>
            <Typography variant="h3" className={classes.whiteText}>Join our team.</Typography>
            <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
          </Grid>
        ) : (
          <Grid item xs={12} lg={6} className={`${classes.leftCol} move-child-icon-to-right`} component={Link} to="/solutions">
            <Typography variant="body1" className={classes.whiteText}>Solutions</Typography>
            <Typography variant="h3" className={classes.whiteText}>Explore our offerings.</Typography>
            <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
          </Grid>
        )}

        <Grid item xs={12} lg={6} className={classes.rightCol}>
          <Typography variant="h4" className={classes.greyText}>Get in Touch</Typography>
          <Typography variant="body1">We’d love to hear from you.</Typography>
          <Typography variant="body1">Just tell us what you want from us.</Typography>
          <ContactForm />
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 0px',
    overflowX: 'hidden',
    background: '#f8f8f8'
  },
  textSecondary: {
    lineHeight: '1.5rem'
  },
  leftCol: {
    background: '#2c6839',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding:  '48px 64px !important',
    textDecoration: 'none !important'
  },
  rightCol: {
    background: '#f1ece4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding:  '48px 64px 24px 64px !important'
  },
  whiteText: {
    color: '#FFF',
    margin: '4px 0',
    textDecoration: 'none !important'
  },
  greyText: {
    fontWeight: 500,
    margin: '4px 0'
  },
  whiteIcon: {
    fill: '#FFF',
    fontSize: '2.7rem',
    position: 'relative'
  },
}));

export default ContactFormWrapper;