import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

const ContactQuestion = ({ data, index, total, activeIndex, setActiveIndex, inputDataHandler, isSubmit, submitContactForm, requesting }) => {
  const [value, setValue] = useState({});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Update the document title using the browser API
    document.getElementById("1").focus();
  }, []);

  const onEnterHandler = (event, index, isSubmit, isRequired) => {

    if (!isSubmit && event.key === 'Enter') {
      if (isRequired && event.target.value === '') {
        setIsError(true)
        setErrorMessage("It's a required field")
      } else {
        setIsError(false)
        setErrorMessage("")
        setActiveIndex(activeIndex+1)
        var element = document.getElementById(index+1);
        element.focus()
      }
    }
  }

  const onClickHandler = (index, field, isRequired) => {
    const fValue = value[`${field}`]
    if ((typeof(fValue) === 'undefined' || fValue === '') && isRequired) {
      setIsError(true)
      setErrorMessage("It's a required field")
    } else {
      setActiveIndex(activeIndex+1)
      var element = document.getElementById(index+1);
      element.focus()
    }
  }

  const onSubmitHandler = (field, isRequired) => {
    const fValue = value[`${field}`]
    if ((typeof(fValue) === 'undefined' || fValue === '') && isRequired) {
      setIsError(true)
      setErrorMessage("It's a required field")
    } else {
      submitContactForm()
    }
  }

  const inputHandler = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    inputDataHandler(e.target.name, e.target.value);
  };

  const classes = useStyles();
  return (
    <div className={`${classes.qContainer} ${activeIndex === index && classes.visible}`}>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Question {index} of {total}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {data.title}
      </Typography>
      <TextField
        id={index}
        name={data.field}
        type={data.fieldType}
        className={classes.textInput}
        required={data.required}
        placeholder={data.placeholder}
        multiline={data.multiline}
        error={isError}
        helperText={errorMessage}
        onKeyDown={(e) => onEnterHandler(e, index, isSubmit, data.required) }
        onChange={
          inputHandler
        }
      />
      {
        isSubmit ?
          <div className={classes.submitRow}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              endIcon={<CheckIcon />}
              classes={{
                label: classes.largeButtonlabel
              }}
              onClick={() => onSubmitHandler(data.field, data.required)}
              disabled={requesting}
            >
              {requesting ? 'PLEASE WAIT' : 'SUBMIT'}
            </Button>
          </div>
          :
          <div className={classes.submitRow}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              endIcon={<CheckIcon />}
              classes={{
                label: classes.largeButtonlabel
              }}
              onClick={() => onClickHandler(index, data.field, data.required)}
            >
              OK
            </Button>
            &nbsp;
            &nbsp;
            <Typography variant="body2" color="textSecondary" gutterBottom>
              press Enter ↵
            </Typography>
          </div>
      }


    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  qContainer: {
    width: '100%',
    opacity: 0,
    position: 'absolute',
    top: 0
  },
  visible: {
    opacity: 1,
    top: 120
  },
  textInput: {
    minWidth: '95%',
    '& input': {
      fontSize: 22
    },
    '& textarea': {
      fontSize: 22
    }
  },
  submitRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0'
  },
  largeButtonlabel: {
    alignItems: 'flex-start'
  },
}));


export default ContactQuestion;
