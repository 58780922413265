import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';

import ContactQuestion from './ContactQuestion';
import assuredImg from '../assets/images/assured-min.png'
import guranteedImg from '../assets/images/quick-response-min.png'
import successImg from '../assets/images/undraw_well_done.svg'
import errorImg from '../assets/images/undraw_error.svg'

const GEOGO_NODE_API_URL = 'https://node.geogo.in'

const sendInBlueLists = [
  {
    title: 'Get our service',
    listId: 2
  },
  {
    title: 'Become our partner',
    listId: 6
  },
  {
    title: 'Join our team',
    listId: 7
  },
  {
    title: 'Discuss about something else',
    listId: 8
  },
]
const questions = [
  {
    title: "Thanks for reaching out. Now let's grab your contact details so we can get back to you. First off, what's your name? *",
    placeholder: "Type your name here...",
    required: true,
    multiline: false,
    field: 'name',
    fieldType: 'text',
  },
  {
    title: 'Great. Could you let us know your email address, _____? *',
    placeholder: "Type your Email ID here...",
    required: true,
    multiline: false,
    field: 'email',
    fieldType: 'email',
  },
  {
    title: "Thanks for that. Could you let us know your contact number, _____? (though it's optional, but recommended)",
    placeholder: "Type your mobile number here...",
    field: 'contact_no',
    fieldType: 'text',
    required: false,
    multiline: false,
  },
  {
    title: "Great. What is your company's name? (it's optional)",
    placeholder: "Type your company's name here (if any)...",
    field: 'company',
    fieldType: 'text',
    required: false,
    multiline: false,
  },
  {
    title: "And finally, what would you like to say? *",
    placeholder: "Type your message here...",
    field: 'message',
    fieldType: 'text',
    required: true,
    multiline: true,
  }
]

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactForm = () => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [qIndex, setQIndex] = useState(0);
  const [sendInBlueList, setSendInBlueList] = useState(null);
  const [formObj, setFormObj] = useState({});
  const [requesting, setRequesting] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const handleClickOpenDialog = (listId) => {
    setOpenDialog(true);
    setQIndex(1);
    setSendInBlueList(listId);
    setRequestSuccess(false)
    setRequestError(false)
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRequestSuccess(false)
    setRequestError(false)
  };

  const inputDataHandler = (name, value) => {
    setFormObj({
      ...formObj,
      [name]: value
    });
  }

  const submitContactForm = () => {
    // console.log(formObj);
    setRequesting(true)
    axios.post(`${GEOGO_NODE_API_URL}/contact`, {
      sendInBlueListId: sendInBlueList,
      data: formObj
    }).then(response => {
      setRequesting(false)
      setRequestSuccess(true)
    })
    .catch( error => {
      console.log("REMOTE SUBMISSION ERROR", error);
      setRequesting(false)
      setRequestError(true)
    });
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h6" className={classes.greyText} gutterBottom>
            You want to...
          </Typography> 
          {sendInBlueLists.map(list => (
            <Button variant="outlined" color="primary" key={list.listId} onClick={() => handleClickOpenDialog(list.listId)} className={classes.questionButton} size="large">
              {list.title}
            </Button>
          ))}
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.flexRow}>
            <img src={assuredImg} alt="Confidentiality" className={classes.infoImg} />
            <div>
              <Typography variant="body1" className={classes.greyText} gutterBottom>
                Confidentiality Assured!
              </Typography>  
              <Typography variant="body2" className={classes.greyText2} gutterBottom>
                We are assuring you, your words will stay within us. If you are willing to share any confidential information or idea with us, we are always committed to protect that.
              </Typography> 
            </div>
          </div>
          <div className={classes.flexRow}>
            <img src={guranteedImg} alt="Confidentiality" className={classes.infoImg} />
            <div>
              <Typography variant="body1" className={classes.greyText} gutterBottom>
                Same Day Response is Guaranteed!
              </Typography>   
              <Typography variant="body2" className={classes.greyText2} gutterBottom>
                We are eagerly waiting to hear from you. Write to us and get guaranteed response within 24hrs.
              </Typography> 
            </div>
          </div>
        </Grid>
      </Grid>

      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <span />
            <Typography variant="h6" className={classes.title}>
              Get in touch with us
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} lg={6}>
              {requestSuccess ? (
                <div className={classes.resultContainer}>
                  <img src={successImg} alt="Success" width="300" />
                  <br />
                  <Typography variant="h4" gutterBottom>Yay! We've got your message.</Typography>
                  <Typography variant="subtitle1" gutterBottom>Our response is on the way. You'll get a reply within 24hrs, that's guaranteed. </Typography>
                  <br />
                  <br />
                  <Button size="large" variant="outlined" onClick={handleCloseDialog}>Let's go back to website</Button>
                </div>
              ) : requestError ? (
                <div className={classes.resultContainer}>
                  <img src={errorImg} alt="Success" width="300" />
                  <br />
                  <Typography variant="h4" gutterBottom>Oops! Something is not right, we're fixing it.</Typography>
                  <Typography variant="subtitle1" gutterBottom>You can try after sometime or write us at <a href="mailto:info@geogo.in" className={classes.whiteText}>info@geogo.in</a>. We are commited to respond within 24hrs.</Typography>
                  <br />
                  <br />
                  <Button size="large" variant="outlined" onClick={handleCloseDialog}>Let's go back to website</Button>
                </div>
              ) : (
                <div className={classes.questionContainer}>
                  {questions.map((q, index) => {
                    return (
                      <ContactQuestion
                        key={q.field}
                        data={q}
                        index={index+1}
                        total={questions.length}
                        activeIndex={qIndex}
                        setActiveIndex={setQIndex}
                        inputDataHandler={inputDataHandler}
                        isSubmit={index === (questions.length - 1) ? true : false}
                        submitContactForm={submitContactForm}
                        requesting={requesting}
                      />
                    );
                  })}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  )
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 0px',
    overflowX: 'hidden',
    width: '100%'
  },
  greyText: {
    fontWeight: 500,
    margin: '4px 0'
  },
  greyText2: {
    color: '#464646'
  },
  flexRow: {
    marginTop: 12,
    minHeight: 72,
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoImg: {
    width: 50,
    marginRight: 12
  },
  appBar: {
    position: 'relative',
    background: theme.palette.secondary[500]
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  questionButton: {
    width: '100%',
    marginBottom: 10,
    textTransform: 'none',
    justifyContent: 'flex-start',
    // border: '2px solid #53c07d',
    border: '2px solid rgba(34, 53, 71, 0.5)',
    '&:hover': {
      border: '2px solid #53c07d',
      // background: 'rgb(48, 111, 67)',
      // color: '#FFF'
    }
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc( 100vh - 140px )',
  },
  questionContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 20px',
  }
}));

export default ContactForm;